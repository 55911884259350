<template>
  <v-navigation-drawer
    class="d-print-none"
    v-model="drawer"
    :color="color"
    permanent
    dark
    app
    v-bind:width="250"
  >
    <div id="navigation-drawer-text">Rezervační systém</div>
    <v-divider></v-divider>
    <v-list
      dense
      nav
      class="py-0"
    >
      <div
        v-for="(item, index) in items"
        :key="index">
        <v-list-item
          link
          :to="{name: `host.${item.linkName}`}"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block light
          @click="logout"
        >Odhlásit se</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',
  data () {
    return {
      drawer: true,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', linkName: 'dashboard' },
        { title: 'Moje karty objektů', icon: 'mdi-format-list-text', linkName: 'properties' },
        { title: 'Moje poptávky', icon: 'mdi-card-text', linkName: 'demands' },
        { title: 'Moje rezervace', icon: 'mdi-card-bulleted-outline', linkName: 'reservations' },
        { title: 'Chaty', icon: 'mdi-forum-outline', linkName: 'chats' }
      ],
      color: 'blue darken-2'
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('userAuth/logout')
    }
  }
}
</script>

<style scoped>
#navigation-drawer-text{
  text-align: center;
  color: #fffefe;
  padding: 1.5rem;
  font-size: 1.2em;
}
.v-list-item__title{
  font-size: 0.9em !important;
}
.v-navigation-drawer{
  width: 250px !important;
}
</style>
