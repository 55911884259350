<template>
  <div>
    <NavigationDrawer app>
    </NavigationDrawer>
    <Navbar></Navbar>
    <AlertPanel />
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from '@/components/layout/user/Navbar'
import AlertPanel from '@/components/layout/AlertPanel'
import NavigationDrawer from './NavigationDrawer'
// import Footer from '../anonymous/Footer'
export default {
  name: 'Layout',
  components: { NavigationDrawer, AlertPanel, Navbar }
}
</script>

<style scoped>
>>>.table-link{
  text-decoration: none;
}
</style>
